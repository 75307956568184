<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" md="8" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Tambah Bloodvan</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama BloodVan</label>
            <b-form-input placeholder="Nama Bloodvan">
            </b-form-input>
          </div>
        </li>
        <li class="mb-3">
          <div class="d-block mb-3">
            <label>Lokasi BloodVan</label>
            <b-form-input placeholder="Masukan Lokasi Bloodvan" class="mb-3">
            </b-form-input>
          </div>
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15840.006747467789!2d107.51694854944672!3d-7.009083087876179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68edd32c81f329%3A0x126c0b750dcc7d67!2sDinas%20Kesehatan%20Kabupaten%20Bandung!5e0!3m2!1sid!2sid!4v1697968611700!5m2!1sid!2sid"
              style="border:0; width: 100%; height: 200px;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
        </li>
        <li class="d-flex justify-content-between">
          <p>Status</p>
          <b-form-checkbox switch size="lg" button-variant="danger">Aktif</b-form-checkbox>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li class="mb-3">
          <label>Layanan</label>
          <b-form-input placeholder="Masukan Layanan BloodVan" class="mb-3">
          </b-form-input>
        </li>
        <li class="mb-3">
          <label>Kontak BloodVan</label>
          <b-form-input placeholder="Masukan Kontak BloodVan" class="mb-3">
          </b-form-input>
        </li>
        <li class="mb-3">
          <label>Jadwal Operasional</label>
          <b-form-group>
            <div v-for="option in options" :key="option.value" class="d-flex">
              <b-form-checkbox v-model="selected" :value="option.value" name="days" class="mr-3 mb-3" stacked>
                {{ option.text }}
              </b-form-checkbox>
              <div style="margin-left: 10px; display: flex; align-items: center;">
                <b-button size="sm" disabled>08:00</b-button>
                -
                <b-button size="sm" disabled>17:00</b-button>
              </div>
            </div>
          </b-form-group>
        </li>
      </ul>
    </b-col>
    <b-col class="d-flex justify-content-end mb-3">
      <b-button variant="danger" class="mr-3">Batal</b-button>
      <b-button variant="info">Simpan</b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";

export default {
  name: 'AddBloodvan',
  data() {
    return {
      selected: [],
      options: [
        {text: "Senin", value: "Senin"},
        {text: "Selasa", value: "Selasa"},
        {text: "Rabu", value: "Rabu"},
        {text: "Kamis", value: "Kamis"},
        {text: "Jumat", value: "Jumat"},
        {text: "Sabtu", value: "Sabtu"},
        {text: "Minggu", value: "Minggu"},
      ]
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox
  }
}
</script>